import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const FirstComponent = () => {
	return (
		<>
			<Container className="pt-3 pb-5 carousel-columns">
				<div className="d-flex justify-content-start align-items-center mt-5">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">The Challenges</h6>
					</div>
				</div>
				<h2 className="text-uppercase">Automation is essential for digital success.</h2>
			
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							Customer expectations and need to accelerate adoption of Digital is putting pressure on organizations to deliver faster and ensure frictionless experience across all digital channels. Embracing automation is the key to success in this.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							&gt; Continuous integration and delivery require mature DevOps practices which need a stable, scalable and reliable test automation solution to meet the needs.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							&gt; More complex environments include multiple channels, devices, anddelivery formats
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							&gt; Digital expansion is allowing companies to reach global customer base. It puts additional pressure to validate application for both globalization and localization testing.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							&gt; Users expect frictionless user experience irrespective of digital channel they use.
						</p>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default FirstComponent;