import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const TheDifferences = () => {
	return (
		<Container fluid className="mt-5" style={{ background: 'rgb(247, 246, 249)' }}>
			<Container className="carousel-columns pt-5">
				<div className="d-flex justify-content-start align-items-center">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">Our Differences</h6>
					</div>
				</div>
				<Row>
					<Col>
						<div className="initial-paragrapgh">
							<u>Increased ROI</u>

						</div>
						<ul>
							<li className="text-list">
								<b>Low Cost of Ownership</b> leveraging open-source tools.
							</li>
							<li className="text-list">
								Reduces Script Development and Maintenance cost by <b>40-60%</b>.
							</li>
						</ul>
					</Col>
					<Col>
						<div className="initial-paragrapgh">
							<u>Proactive Test Planning using Machine Learning</u>

						</div>
						<ul>
							<li className="text-list">
								<b>SMART Regression</b> by <b>Point of Failure Analysis</b>.
							</li>
							<li className="text-list">
								Uses <b>engineering lifecycle</b> data for risk establishment.
							</li>
						</ul>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="initial-paragrapgh">
							<u>High Test Coverage</u>

						</div>
						<ul>
							<li className="text-list">
								<b>Parallel test execution</b> on Multiple browsers and Devices for <b>increased test coverage but reduced cycle time</b>.
							</li>
							<li className="text-list">
								Omnichannel approach for test automation to enable user journey-based validations for Web and Mobile interfaces.
							</li>
						</ul>
					</Col>
					<Col>
						<div className="initial-paragrapgh">
							<u>Self Service Transparent Reporting</u>

						</div>
						<ul>
							<li className="text-list">
								Online <b>Executive Metrics Dashboard</b> for consolidated viewacross multiple applications and teams built using integrationwith JIRA.
							</li>
							<li className="text-list">
								<b>Pre-built integrations</b> with all <b>popular Requirement, Test and Defect Management and CI Tools</b>.
							</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default TheDifferences;