import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/service/banner.png'
import CaseStudyComponent from '../components/services/CaseStudyComponent'
import TestimonialComponent from '../components/services/TestimonialComponent'
import FirstComponent from '../components/testautomation/FirstComponent'
import TheSolution from '../components/testautomation/TheSolution'
import TheOfferings from '../components/testautomation/TheOfferings'
import TheDifferences from '../components/testautomation/TheDifferences'
class TestAutomationContainer extends Component {
	render() {
		return (
			<>
				<BannerImage
					activeTab="Test Automation"
					bannerImage={banner}
					text="Enable faster time-to-market for your enterprise apps with a scalable Test Automation Solution .. GAINS" />
				<FirstComponent />
				<TheSolution />
				<TheOfferings />
				<TheDifferences />
				<CaseStudyComponent />
				<TestimonialComponent />
			</>
		)
	}
}

export default TestAutomationContainer