import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const TheSolution = () => {
	return (
		<Container fluid style={{ background: 'rgb(247, 246, 249)' }}>
			<Container className="carousel-columns pt-5 ">
				<div className="d-flex justify-content-start align-items-center">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">The Solution</h6>
					</div>
				</div>
				<h2>GTEN TEST AUTOMATION SOLUTION- GAINS</h2>
			</Container>
			<Container className="pb-5">
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							GTEN Test Automation Solution GAINS is a tried and tested open-source based test automation solution which is language or platform-independent testing solution for automating and validating complex business processes in multiple industries. GAINS also has integration with a number of popular commercial and open-source test tools.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							GAINS helps to accelerate, scale and enhance test automation coverage and reliability and help you overcome the:

						</p>
						<ul>
							<li className="text-list">Inability to choose the best suited test automation tools.</li>
							<li className="text-list">Lack of pre-built automated test suites and a team of scripting experts.</li>
							<li className="text-list">Migration challenges in while modernizing legacy automation scripts.</li>
							<li className="text-list">Lack of expertise in automating regression tests.</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default TheSolution;