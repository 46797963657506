import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import TestAutomationContainer from '../containers/TestAutomationContainer'

const TestAutomation = (props) => (
	<Layout
		pageInfo={{ pageName: "Test Automation" }}
		styleClass="home-page "
		location={props.location.pathname}>
		<SEO title="Test Automation" />
		<TestAutomationContainer />
	</Layout>
)

export default TestAutomation
